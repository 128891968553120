import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Header = () => (
  <header className="block sm:flex md:block justify-between items-center w-92 md:w-full">
    <figure className="w-18 h-18">
      <StaticImage src="../images/me.png" alt="me" placeholder="blurred" />
    </figure>
    <h1 className="mt-2 sm:mt-0 md:mt-2 text-lg font-semibold">
      Nathan Kowalski
    </h1>
    <span className="hidden sm:inline-block md:hidden">|</span>
    <p className="mt-2 sm:mt-0 md:mt-2 text-gray-600">Web Developer</p>
  </header>
)

export default Header
