import React from "react"
import { Link } from "gatsby"

const Nav = () => (
  <nav>
    <ul className="block sm:flex md:block justify-between items-center">
      <li>
        <Link className="hover:text-blue-400 hover:underline" to="/">
          Home
        </Link>
      </li>
      <li className="mt-2 sm:mt-0 md:mt-2">
        <Link className="hover:text-blue-400 hover:underline" to="/about/">
          About
        </Link>
      </li>
      <li className="mt-2 sm:mt-0 md:mt-2">
        <Link className="hover:text-blue-400 hover:underline" to="/experience/">
          Experience
        </Link>
      </li>
      <li className="mt-2 sm:mt-0 md:mt-2">
        <Link className="hover:text-blue-400 hover:underline" to="/education/">
          Education
        </Link>
      </li>
      <li className="mt-2 sm:mt-0 md:mt-2">
        <Link className="hover:text-blue-400 hover:underline" to="/skills/">
          Skills
        </Link>
      </li>
      <li className="mt-2 sm:mt-0 md:mt-2">
        <Link className="hover:text-blue-400 hover:underline" to="/portfolio/">
          Portfolio
        </Link>
      </li>
    </ul>
  </nav>
)

export default Nav
